import ReactGA from 'react-ga4';
import lscache from 'lscache';

const reporting = 'https://reports.setupad.com/setupad';
//const workbench = 'https://wb.setupad.com/api';

const paymentsApi =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5001/api'
    : 'https://api-payments.setupad.io/api';

const workbench = process.env.NODE_ENV === 'development' ? '/api' : 'https://wb.setupad.com/api';

const reportingDB =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:6001'
    : 'https://api-db-reporting.setupad.com';

export const endpoints = {
  addCustomEntries: `${workbench}/addCustomEntries`,
  getAdsTXT: `${workbench}/getAdsTXT`,
  checkAdsTxt: `${workbench}/checkAdsTxt/client`,
  googleAuth: `${workbench}/client/googleAuth`,
  getAnalyticsProperties: `${workbench}/client/getAnalyticsProperties`,
  getAssignedGoogleProperties: `${workbench}/client/getAssignedGoogleProperties`,
  getAnalyticsDataForDomain: `${workbench}/client/getAnalyticsDataForDomain`,
  getAnalyticsDataForOriginSources: `${workbench}/client/getAnalyticsDataForOriginSources`,
  getDecilesData: `${workbench}/client/getDecilesData`,
  getDecilesDataBySSP: `${workbench}/client/getDecilesDataBySSP`,
  assignGoogleProperty: `${workbench}/client/assignGoogleProperty`,
  disconnectGoogleProperty: `${workbench}/client/disconnectGoogleProperty`,
  authenticate: `${workbench}/client/authenticate`,
  refreshMenu: `${workbench}/client/refreshMenu`,
  feedback: `${workbench}/client/feedback`,
  totalclient: `${reporting}/web/api/totalclient`,
  totalclientsetupad: `${reporting}/web/api/totalclientsetupad`,
  totalclientstacked: `${reporting}/web/api/totalclientstacked`,
  clientperiod: `${reporting}/web/api/clientperiod`,
  clientperiodsetupad: `${reporting}/web/api/clientperiodsetupad`,
  lastMonthRevenue: `${reporting}/api/payments/revenue`,
  minimumPayout: `${reporting}/api/payments/minimum`,
  invoices: `${reporting}/api/payments/invoices`,
  scheduledRevenues: `${workbench}/client/scheduledRevenues`,
  billingDetails: `${reporting}/api/billing`,
  remindPassword: `${workbench}/client/remindPassword`,
  resetPassword: `${workbench}/client/resetPassword`,
  extensionReports: `${workbench}/client/extensionReports`,
  acceptTos: `${workbench}/client/acceptTos`,
  getRealtimeData: `${workbench}/realtime/client/getRealtimeFromGDF`,
  getGeoData: `${workbench}/client/getGeoData`,
  billingDetailsChangeNotification: `${workbench}/client/billingDetailsChangeNotification`,
  sendSecurityCode: `${workbench}/client/sendSecurityCode`,
  getAuth: `${workbench}/client/getAuth`,
  getOriginData: `${workbench}/client/getOriginData`,
  getNotificationMessage: `${workbench}/client/getNotificationMessage`,
  shareGraph: `${workbench}/sharing/share`,
  getSharedData: `${workbench}/sharing/getData`,
  addCMP: `${reportingDB}/api/cmp/add`,
  getCMP: `${reportingDB}/api/cmp/get`,
  checkCurrentCMP: `${reportingDB}/api/cmp/client/domain`,
  checkCMPbyUrl: `${reportingDB}/api/cmp/client/url`,
  fullVendorsList: `https://cmp.setupcmp.com/cmp/gvl/additional-gvl-information.json`,
  enabledByDefaultVendors: `https://cmp.setupcmp.com/cmp/gvl/default-vendors.json`,
  getDomainCategories: `${workbench}/domainCategoryClientUI`,

  // Adserver Domain history
  getDomainHistory: `${workbench.replace(
    '/api',
    ''
  )}/database-api/api/adserver/getDomainHistoryClientUI/domainId`,

  // Onboarding:
  setOnboardingCompleted: `${workbench}/client/setOnboardingCompleted`,

  // MCM:
  getPublishers: `${workbench}/mcm/publishers`,
  getMCMstatus: `${workbench}/mcm/getMCMstatus`,
  getSitesByNetworkCode: `${workbench}/mcm/getSitesByNetworkCode`,
  inviteChildPublisher: `${workbench}/mcm/child/invite`,

  // Self serve:
  assignSelfServeAuth: `${workbench}/client/selfServe/assignAuth`,
  getAssignedSelfServeAuth: `${workbench}/client/selfServe/getAssignedAuth`,
  getAllNetworks: `${workbench}/client/selfServe/getAllNetworks`,
  assignNetwork: `${workbench}/client/selfServe/assignNetwork`,
  createTestNetwork: `${workbench}/client/selfServe/createTestNetwork`,
  getSitesList: `${workbench}/client/selfServe/getSitesList`,
  createSiteAndDomain: `${workbench}/client/selfServe/createSiteAndDomain`,
  createPublisher: `${workbench}/client/selfServe/createPublisher`,
  importDomain: `${workbench}/client/selfServe/importDomain`,
  generateOrders: `${workbench}/client/selfServe/orderGenerator/generateOrders`,
  getOrderStatus: `${workbench}/client/selfServe/orderGenerator/orderStatus`,
  getHistoricalDataStatus: `${workbench}/client/selfServe/dataFetcher/status`,
  consumedCreditsStart: `${workbench}/client/selfServe/dataFetcher/consumedCreditsStart`,
  createPaymentIntent: `${paymentsApi}/client/create-payment-intent`,
  createSubscription: `${paymentsApi}/client/subscription/create`,
  sendReferralEmail: `${paymentsApi}/referral`,
  getSubscription: `${paymentsApi}/client/subscription`,
  getInvoices: `${paymentsApi}/client/invoices`,
  getCustomer: `${paymentsApi}/client/customer`,
  getPrices: `${paymentsApi}/client/prices`,
  addPaymentMethod: `${paymentsApi}/client/addPaymentMethod`,
  changeDefaultPm: `${paymentsApi}/client/changeDefaultPm`,
  deletePaymentMethod: `${paymentsApi}/client/deletePaymentMethod`,
  cancelSubscription: `${paymentsApi}/client/subscription/cancel`,
  createPaymentIntentOneTime: `${paymentsApi}/client/createPaymentIntentOneTime`,
  signEnterpise: `${paymentsApi}/client/signEnterpise`,
  taxCounter: `${paymentsApi}/client/taxCounter`,

  // Public Knowledge Base:
  publicArticlesList: `${workbench}/public/articles/publicList`,
  getPublicArticleById: `${workbench}/public/article`,

  //Ticketing
  submitTicket: `${workbench}/client/ticketing/submitTicket`,
  getTicketsByAccountId: `${workbench}/client/ticketing/getTicketsByAccountId`,
  addComment: `${workbench}/client/ticketing/addComment`,
  getImageAttachment: `${workbench}/client/ticketing/getImageAttachment`,

  // Inventory
  getDataForDomain: `${reportingDB}/api/adserver/client/getDataForDomain`,
};

export const APICall = query => {
  //console.log('API call', query)
  return new Promise((resolve, reject) => {
    ReactGA.event({
      category: 'API',
      action: 'call',
      label: query.endpoint,
    });

    let request = {
      url: `${endpoints[query.endpoint]}${query.params ? `/${query.params}` : ''}`,
      options: query.options ? query.options : {},
    };

    fetch(request.url, request.options)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (query.endpoint === 'authenticate') {
            resolve(response.json());
          } else {
            if (response.status === 401) {
              lscache.remove('auth');
              window.location.replace('/login');
            } else {
              resolve({success: false});
            }
          }
        }
      })
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        ReactGA.event({
          category: 'API',
          action: 'error',
          label: error.message,
          value: query.endpoint,
        });
        reject(error);
      });
  });
};
